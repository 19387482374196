
  /* Specific styles for the Apple Music logo, if needed */
  .music-logo {
    width: 50px; /* Adjust the size as needed */
    height: 60px;
  }

  .music-logo-two{
    width: 40px;
    height: 45px;
    transform: translateY(-7px); /* Moves the element upwards */
  }

  .music-logo-three {
    width: 50px;
    transform: translateY(-4px);
    margin-left: 5px;
    
  }
  
  .slide-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
  
  .responsive-iframe {
    position: relative;
    overflow: hidden;
    width: 90%; /* Adjust this value to control the width */
    padding-top: 50.25%; /* Aspect Ratio: (height / width * 100%) for 16:9 aspect ratio */
    max-height: 500px;
  }
  
  .responsive-iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  .scroll-up-logo {
    width: 100%; /* Full width to center content */
    position: absolute; /* Position it relative to the container */
    bottom: 20px; /* 20px from the bottom */
    display: flex;
    justify-content: center; /* Center horizontally */
    height: 50px;
    width: 50px;
    transform: rotate(180deg); /* Rotate 180 degrees */
  }

  