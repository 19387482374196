.slideOneContainer {
  width: 100vw;
  height: 100vh;
  overflow: hidden; /* Hide scrollbar */
}

.title{
  font-size: 1.75em;
}


.scroll-down-logo {
  width: 100%; /* Full width to center content */
  position: absolute; /* Position it relative to the container */
  bottom: 20px; /* 20px from the bottom */
  display: flex;
  justify-content: center; /* Center horizontally */
  height: 50px;
  width: 50px;
  
}


.circle {
  position: absolute;
  bottom: 0;
  width: 50px;
  height: 50px;
  border: 2px solid black;
  border-radius: 50%;
  background-color: red;
  animation: moveUp 5s infinite;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5); /* Shadow effect */
  text-shadow: 0 0 8px red; /* Glowing effect */
  transition: box-shadow 0.5s, text-shadow 0.5s; /* Transition for dynamic glow */
}

@keyframes moveUp {
  0% {
    bottom: 0;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
    text-shadow: 0 0 8px red;
  }
  50% {
    box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.7);
    text-shadow: 0 0 15px red;
  }
  100% {
    bottom: 100vh;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
    text-shadow: 0 0 8px red;
  }
}
